import { FormattedMessage } from 'react-intl';
import classnames from 'classnames/bind';

import { HeroCarouselIndicator } from './HeroCarouselIndicator';

import s from './HeroCarouselIndicators.module.scss';

const c = classnames.bind(s);

type Props = {
  count: number;
  handleUpdate: (args: number) => void;
  activeItem: number;
  inView?: boolean;
  animate?: boolean;
};

export const HeroCarouselIndicators = ({
  count,
  handleUpdate,
  activeItem,
  animate = true,
}: Props) => {
  const items = [];

  for (let i = 0; i < count; i++) {
    items.push(
      <li key={i}>
        <HeroCarouselIndicator
          animate={animate}
          setActive={() => {
            handleUpdate(i);
          }}
          active={i === activeItem}
          key={i}
        >
          <FormattedMessage
            id="go_to_slide"
            defaultMessage={`Fara á glæru number ${i + 1}`}
            values={{ number: i + 1 }}
          />
        </HeroCarouselIndicator>
      </li>
    );
  }

  return <ol className={c(s.heroCarouselIndicators)}>{items}</ol>;
};
