interface LightningProps {
  className?: string
  strokeWidth?: number
}

const LightningIcon = (props: LightningProps) => {
  const { className, strokeWidth = 3.5 } = props
  return (
    <svg
      width="18"
      height="24"
      viewBox="0 0 18 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M8.08057 3L3 12.1448H15.1931L10.1126 21.2897"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
export default LightningIcon
