import React, {
  cloneElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { FormattedMessage } from 'react-intl'
import { CSSTransition } from 'react-transition-group'
import classnames from 'classnames/bind'
import { UIContext } from 'context/ui'
import { useResize } from 'hooks/useResize'

import s from './HeroCarouselItem.module.scss'
import cx from 'classnames'
import LightningButton from 'components/new-buttons/LightningButton'
import { formatPowerPackageTitle } from 'utils/powerPackageHelper'

const c = classnames.bind(s)

export type HeroCarouselItemProps = {
  children: React.ReactElement
  title?: string
  image?: React.ReactElement
  video?: React.ReactElement
  active?: boolean
  setIsTransitioning?: (args: boolean) => void
  isTransitioning?: boolean
  direction?: number
  link?: string
  linkText?: string
  ariaLabel?: string
  animate?: boolean
  // Is it the first item? Used to choose if <h1> or not
  first?: boolean
  onClick?: () => void
  ctaVideo?: string
  safariCtaVideo?: string
}

export const HeroCarouselItem = ({
  children,
  title,
  image,
  video,
  active,
  setIsTransitioning,
  direction,
  link,
  linkText,
  ariaLabel,
  animate = true,
  first = false,
  onClick,
  ctaVideo,
  safariCtaVideo,
}: HeroCarouselItemProps) => {
  const [_animate, setAnimate] = useState(animate)
  const initialRender = useRef(true)
  const nodeRef = useRef<HTMLDivElement>(null)
  const { uiState } = useContext(UIContext)
  const [duration, setDuration] = useState(2000)
  const { isMobile } = useResize()

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false
      return
    }
    setDuration(isMobile ? 1200 : 1500)
  }, [isMobile])

  useEffect(() => {
    setAnimate(animate)
  }, [animate])

  const TitleElement = first ? 'h1' : 'div'

  const { preTitle, postTitle } = formatPowerPackageTitle(title ?? '')

  const content = () => (
    <div
      ref={nodeRef}
      className={c(s.heroCarouselItem, {
        active,
        animate,
        reverse: direction === 0,
        hasVideo: video && !isMobile,
      })}
      aria-label={ariaLabel}
      aria-roledescription="slide"
      role="group"
    >
      <div className={s.heroCarouselItem__mediaWrap}>
        {video && !isMobile
          ? cloneElement(video, {
              paused: !active,
              autoplay: true,
              isBackgroundVideo: true,
              className: s.heroCarouselItem__video,
            })
          : image &&
            cloneElement(image, {
              className: s.heroCarouselItem__media,
              draggable: false,
            })}
      </div>

      <div className={s.heroCarouselItem__grid}>
        <div
          className={cx(s.heroCarouselItem__contentWrap, {
            [s.heroCarouselItem__contentWrapWithVideo]: ctaVideo && !isMobile,
          })}
          style={ctaVideo && !isMobile ? {} : { gap: 0 }}
        >
          <div className={s.contentWrapperShadow} />

          {ctaVideo && !isMobile && (
            <video
              className={s.ctaVideo}
              autoPlay
              width={100}
              height={300}
              muted

              // onEnded={() => {

              // }}
              // onPlay={(el) => {

              // }}
            >
              {safariCtaVideo && (
                <source
                  src={safariCtaVideo}
                  type={`video/mp4; codecs="hvc1"`}
                />
              )}

              <source src={ctaVideo} type="video/webm" />
            </video>
          )}
          <div
            className={s.textWrapper}
            style={{
              marginLeft: ctaVideo && !isMobile ? 180 : 0,
            }}
          >
            {title && (
              <TitleElement
                className={s.heroCarouselItem__title}
                aria-label={title}
              >
                <b>{preTitle}</b>
                {postTitle}
              </TitleElement>
            )}
            <div className={s.heroCarouselItem__content}>
              {cloneElement(children, { className: s.heroCarouselItem__text })}
            </div>
            {link && (
              <div
                className={c(s.heroCarouselItem__link, { animate: _animate })}
              >
                <LightningButton
                  link={link === '#' ? undefined : link}
                  onClick={onClick}
                >
                  {linkText ?? (
                    <FormattedMessage
                      id="hero_see_more"
                      defaultMessage="Sjá nánar"
                    />
                  )}
                </LightningButton>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={active}
      appear
      classNames={{ ...s }}
      onEnter={() => setIsTransitioning?.(true)}
      onEntered={() => setIsTransitioning?.(false)}
      timeout={uiState.prefersReducedMotion ? 0 : duration}
    >
      {content()}
    </CSSTransition>
  )
}
