import {
  Children,
  cloneElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useInView } from 'react-intersection-observer'
import { useSwipeable } from 'react-swipeable'
import { TransitionGroup } from 'react-transition-group'
import classnames from 'classnames/bind'
import Head from 'next/head'

import { useKeyDown } from 'hooks/useKeyDown'
import { FormatArray } from 'utils/cleverCropping'

import { HeroCarouselIndicators } from './HeroCarouselIndicators'
import { HeroCarouselItemProps } from './HeroCarouselItem'

import s from './HeroCarousel.module.scss'
import SliceContainer from 'containers/slices/SliceContainer'

const c = classnames.bind(s)

export const HeroCarousel = ({
  children,
  preloadImages,
  themes,
}: {
  children: React.ReactNode
  preloadImages?: FormatArray
  themes: Array<'light' | 'dark'>
}) => {
  const childArray = Children.toArray(children).filter(Boolean)
  const [isTransitioning, setIsTransitioning] = useState(false)
  const { ref, inView } = useInView()
  const [direction, setDirection] = useState(1)
  const [activeItem, setActiveItem] = useState(0)
  const childCount = Children.count(children)
  const initialRender = useRef(true)
  const [animate, setAnimate] = useState(false)

  const handleNext = useCallback(() => {
    if (isTransitioning) {
      return
    }

    setDirection(1)
    setActiveItem((prevIndex) =>
      prevIndex + 1 <= childCount - 1 ? prevIndex + 1 : 0
    )
  }, [childCount, isTransitioning])

  const handlePrev = useCallback(() => {
    if (isTransitioning) {
      return
    }

    setDirection(0)

    if (activeItem - 1 < 0) {
      setActiveItem(childCount - 1)
    } else {
      setActiveItem(activeItem - 1)
    }
  }, [activeItem, childCount, isTransitioning])

  const swipeHandler = useSwipeable({
    onSwipedLeft: () => handleNext(),
    onSwipedRight: () => handlePrev(),
    // preventDefaultTouchmoveEvent: true, //TODO: broke the build
    trackMouse: false,
  })

  useKeyDown(['ArrowLeft', 'ArrowRight'], (event) => {
    switch (event?.key) {
      case 'ArrowLeft':
        handlePrev()
        break
      case 'ArrowRight':
        handleNext()
        break
      default:
    }
  })

  const handleClick = (i: number) => {
    if (isTransitioning) {
      return
    }

    setActiveItem(i)
  }

  useEffect(() => {
    setAnimate(true)
  }, [])

  return (
    <>
      {preloadImages && (
        <Head>
          {preloadImages.map((item, i) => (
            <link
              key={`carousel-image-preload-${i}`}
              rel="preload"
              as="image"
              href={item.srcSet}
              media={item.media}
            />
          ))}
        </Head>
      )}
      <section
        aria-roledescription="carousel"
        className={c(s.heroCarousel, themes[activeItem], {
          animate: !initialRender.current,
        })}
        {...swipeHandler}
      >
        <div
          className={s.heroCarousel__inner}
          ref={ref}
          id="hero-carousel"
          aria-live={isTransitioning ? 'off' : 'polite'}
        >
          <TransitionGroup component={null}>
            {childArray.map((item, i) =>
              cloneElement(item as React.ReactElement<HeroCarouselItemProps>, {
                active: i === activeItem,
                isTransitioning,
                setIsTransitioning,
                key: `${s.heroCarousel}${i}`,
                animate,
                direction,
              })
            )}
          </TransitionGroup>
        </div>

        {childCount > 1 && (
          <div className={s.heroCarousel__indicators}>
            <HeroCarouselIndicators
              count={childCount}
              activeItem={activeItem}
              handleUpdate={handleClick}
              inView={inView}
              animate={animate}
            />
          </div>
        )}
      </section>
    </>
  )
}
