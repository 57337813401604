import { CardGrid } from 'components/card-grid/CardGrid';
import { SectionHeaderProps } from 'components/section-header/SectionHeader';

import s from './ArticleGrid.module.scss';

type Props = {
  children: React.ReactNode;
  header?: React.ReactElement<SectionHeaderProps>;
};

export const ArticleGrid = ({ children, header }: Props) => {
  if (!children) {
    return null;
  }

  return (
    <CardGrid className={s.articleGrid} header={header}>
      {children}
    </CardGrid>
  );
};
