import { useRef } from 'react';
import classnames from 'classnames/bind';

import s from './HeroCarouselIndicator.module.scss';

const c = classnames.bind(s);

type Props = {
  onComplete?: () => void;
  active?: boolean;
  setActive?: () => void;
  duration?: number;
  children?: React.ReactNode;
  inView?: boolean;
  isPaused?: boolean;
  animate?: boolean;
};

export const HeroCarouselIndicator = ({
  setActive,
  active,
  children,
  animate = true,
}: Props) => {
  const indicatorRef = useRef<HTMLButtonElement>(null);

  const handleClick = () => {
    if (!active) {
      setActive?.();
    }
  };

  return (
    <button
      ref={indicatorRef}
      aria-controls="hero-carousel"
      className={c(s.heroCarouselIndicator, {
        active,
        animate,
      })}
      onClick={handleClick}
    >
      <span className={s.heroCarouselIndicator__text}>{children}</span>
    </button>
  );
};
