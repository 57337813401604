import { Children } from 'react';

import s from './SectionHeaderActions.module.scss';

type SectionHeaderActionsProps = { children: React.ReactNode };

export const SectionHeaderActions = ({ children }: SectionHeaderActionsProps) => {
  const childArray = Children.toArray(children).filter(Boolean);

  if (!childArray.length) {
    return null;
  }

  return (
    <div className={s.sectionHeaderActions}>
      {childArray.map((item, i) => (
        <div key={i} className={s.sectionHeaderActions__item}>
          {item}
        </div>
      ))}
    </div>
  );
};
