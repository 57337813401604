import { Children, cloneElement } from 'react';
import c from 'classnames';
import { domAnimation, LazyMotion, m } from 'framer-motion';

import { SectionHeaderProps } from 'components/section-header/SectionHeader';

import { staggerFadeInVariant } from 'utils/motion';

import s from './CardGrid.module.scss';

type Props = {
  children: React.ReactNode;
  className?: string;
  batch?: number;
  header?: React.ReactElement<SectionHeaderProps>;
};

export const CardGrid = ({ children, className, batch = 3, header }: Props) => {
  const childArray = Children.toArray(children);
  const variants = staggerFadeInVariant(batch);

  if (!childArray.length) {
    return null;
  }
  return (
    <div className={c(s.cardGrid, className)}>
      {header && cloneElement(header, { className: s.cardGrid__header })}
      <ul className={c(s.cardGrid__grid, className)}>
        {childArray.map((item, i) => (
          <LazyMotion features={domAnimation} key={i}>
            <m.li
              className={s.cardGrid__item}
              initial="hidden"
              custom={i}
              viewport={{ once: true, amount: 0.65 }}
              variants={variants}
              whileInView="visible"
            >
              {item}
            </m.li>
          </LazyMotion>
        ))}
      </ul>
    </div>
  );
};
