import { FormattedMessage } from 'react-intl';
import c from 'classnames';

import { Button } from 'components/button/Button';
import { GridLayout, GridLayoutItem } from 'components/grid/GridLayout';
import { H1, H2 } from 'components/heading/Heading';

import { HeadingTags } from 'types/html-types';

import { SectionHeaderActions } from './SectionHeaderActions';

import s from './SectionHeader.module.scss';

export type SectionHeaderProps = {
  title: string;
  titleEl?: HeadingTags;
  link?: string;
  linkText?: string;
  children?: React.ReactNode;
  offset?: boolean;
  className?: string;
};

export const SectionHeader = ({
  title,
  titleEl = 'h2',
  link,
  linkText,
  children,
  offset = true,
  className,
}: SectionHeaderProps) => {
  const HeaderEl = titleEl === 'h1' ? H1 : H2;
  return (
    <GridLayout className={c(s.sectionHeader, className)}>
      <GridLayoutItem
        offset={{ wide: offset ? 1 : 0 }}
        className={s.sectionHeader__inner}
      >
        <HeaderEl className={s.sectionHeader__title}>{title}</HeaderEl>
        <div className={s.sectionHeader__actions}>
          <SectionHeaderActions>
            {children}
            {link && (
              <Button to={link}>
                {linkText ?? (
                  <FormattedMessage id="see_more" defaultMessage="Sjá meira" />
                )}
              </Button>
            )}
          </SectionHeaderActions>
        </div>
      </GridLayoutItem>
    </GridLayout>
  );
};
