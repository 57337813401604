import { linkResolver } from 'prismic/linkResolver'
import { motion, useAnimationControls, Variants } from 'framer-motion'
import Lightning from './LightningIcon'

import { Link } from 'components/link/Link'
import { useState } from 'react'

import styles from './LightningButton.module.scss'
import { SpringTransition } from './constants'
import { PrismicLinkWithMeta } from 'prismic/types/link'
import cx from 'classnames'

interface LightningButtonProps {
  link: PrismicLinkWithMeta | string | undefined
  text?: string
  metaUid?: string
  children?: React.ReactNode
  onClick?: () => void
  isSmall?: boolean
  disabled?: boolean
}

export const lightningRotationVariants: Variants = {
  hover: {
    rotate: 0,
    color: '#FFFFFF',
    transition: SpringTransition,
  },
  initial: {
    rotate: 180,
    color: '#46C28C',
    transition: SpringTransition,
  },
}

const LightningButton = (props: LightningButtonProps) => {
  const {
    link,
    text,
    children,
    onClick,
    isSmall = false,
    disabled = false,
  } = props
  const [isLightningHovered, setIsLightningHovered] = useState(false)
  const lightningRotation = useAnimationControls()

  const url = linkResolver(link)

  const buttonContent = (
    <>
      <div className={styles.backgroundGradient} />
      <span className={styles.buttonTextLarge}>
        <motion.div
          animate={lightningRotation}
          variants={lightningRotationVariants}
          initial="initial"
          className={styles.lightningWrapper}
        >
          <Lightning
            className={styles.lightningIcon}
            strokeWidth={isLightningHovered ? 2 : 3.5}
          />
        </motion.div>
        {children ? children : text}
      </span>
    </>
  )

  //**If link is undefined we treat it as a button */
  return link ? (
    <Link
      to={url}
      className={cx(styles.lightningButton, { [styles.buttonSmall]: isSmall })}
      onMouseEnter={() => {
        lightningRotation.start('hover')
        setIsLightningHovered(true)
      }}
      onMouseLeave={() => {
        lightningRotation.start('initial')
        setIsLightningHovered(false)
      }}
      onClick={onClick}
    >
      {buttonContent}
    </Link>
  ) : (
    <button
      className={styles.lightningButton}
      disabled={disabled}
      onMouseEnter={() => {
        lightningRotation.start('hover')
        setIsLightningHovered(true)
      }}
      onMouseLeave={() => {
        lightningRotation.start('initial')
        setIsLightningHovered(false)
      }}
      onClick={onClick}
    >
      {buttonContent}
    </button>
  )
}
export default LightningButton
